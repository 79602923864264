<template>
	<div class="footer-navigation__links">
		<router-link class="footer-navigation__link" :to="link.url" v-for="link in footerLinks[column]" :key="link.alias">{{
			link.title
		}}</router-link>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		column: Number,
	},
	computed: {
		...mapState({
			footerLinks: (state) => state.FooterLinksStore.footerLinks,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
