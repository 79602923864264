<template>
	<router-link
		class="navigation__menu-list__main-link sub-link"
		:key="link.alias"
		:to="link.url"
		:download="link.download"
		>{{ link.title }}</router-link
	>
</template>

<script>
export default {
	props: {
		link: Object,
	},
};
</script>

<style lang="scss" scoped></style>
