<template>
	<nav class="navigation">
		<div class="navigation__wrapper navigation__wrapper_baseline">
			<button class="navigation__menu-btn navigation__menu-btn_hidden" @click="enableMobileMenu = !enableMobileMenu">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
					<path
						d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z"
					/>
				</svg>
			</button>
			<div class="navigation__menu-list" @click="visibleSubMenu = false">
				<router-link
					class="navigation__menu-list__main-link"
					:to="link.url"
					v-for="link in mainMenu"
					:key="link.alias"
					>{{ link.title }}
				</router-link>
			</div>
		</div>

		<div class="navigation__mobile-menu-list" v-show="enableMobileMenu">
			<router-link
				class="navigation__mobile-menu-list__main-link"
				:to="link.url"
				v-for="link in mainMenu"
				:key="link.alias"
				>{{ link.title }}</router-link
			>
		</div>
	</nav>
</template>

<script>
import { mapState } from 'vuex';
import HeaderSubmenuComponent from './HeaderSubmenuComponent.vue';

export default {
	components: { HeaderSubmenuComponent },
	data() {
		return {
			enableMobileMenu: false,
		};
	},
	computed: {
		...mapState({
			mainMenu: (state) => state.HeaderNavigationStore.homePageMenu,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
