<template>
	<HeaderComponent :isDisable="true" :isHomeNavigation="false" />
	<article class="federalServiceDepartment container">
		<h2 class="federalServiceDepartment__title">
			УПРАВЛЕНИЕ ФЕДЕРАЛЬНОЙ СЛУЖБЫ ПО НАДЗОРУ В СФЕРЕ ЗАЩИТЫ ПРАВ ПОТРЕБИТЕЛЕЙ И БЛАГОПОЛУЧИЯ ЧЕЛОВЕКА ПО ЗАПОРОЖСКОЙ
			ОБЛАСТИ
		</h2>
		<p class="federalServiceDepartment__text">
			<span class="federalServiceDepartment__text_bold">Юридический адрес:</span> 272309, Мелитопольский р-н, г.
			Мелитополь, ул. Сухова, д. 6
		</p>
		<p class="federalServiceDepartment__text">
			<span class="federalServiceDepartment__text_bold">Руководитель:</span> Хаттатова Наталья Владиславовна
		</p>
		<p class="federalServiceDepartment__text">
			<span class="federalServiceDepartment__text_bold">Для направления обращений граждан:</span> e-mail:
			<a href="mailto:urpn_85@rospotrebnadzor.ru">urpn_85@rospotrebnadzor.ru</a>
		</p>
	</article>
	<YandexMap
		:coordinates="baseCoordinates"
		:zoom="zoom"
		:controls="controls"
		class="federalServiceDepartmentMap container"
	>
		<YandexMarker :coordinates="filialList[0].coordinates" :marker-id="filialList[0].id">
			<template v-slot:component>
				<BalloonComponent :filial="filialList[0]" />
			</template>
		</YandexMarker>
	</YandexMap>
	<!-- <FooterNavigationComponent /> -->
</template>

<script>
import { YandexMap, YandexMarker } from 'vue-yandex-maps';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';
import HeaderComponent from '@/components/UI/HeaderComponent.vue';
import FooterNavigationComponent from '@/components/UI/Footer/FooterNavigationComponent.vue';
import BalloonComponent from '@/components/YandexMap/BalloonComponent.vue';

export default {
	components: {
		filialList: computed(() => store.state.FilialsStore.filialList),
		HeaderComponent,
		FooterNavigationComponent,
		BalloonComponent,
	},

	setup() {
		useHead({
			title:
				'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» | Управление Федеральной Службы по надзору в сфере защиты прав потребителей и благополучия человека по Запорожской области',
			meta: [
				{
					name: 'federalServiceDepartment',
					content: 'Филиалы ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»',
				},
			],
		});

		const controls = [];
		const zoom = 18;
		const baseCoordinates = ref([46.836061, 35.371046]);
		const store = useStore();

		return {
			filialList: computed(() => store.state.FilialsStore.filialList),
			baseCoordinates,
			controls,
			zoom,
		};
	},
};
</script>

<style lang="scss" scoped></style>
