import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createHead } from '@vueuse/head';
import YmapPlugin from 'vue-yandex-maps';

import './assets/scss/main.scss';

const settings = {
	apiKey: '373a66c6-62f6-44fd-a381-8312e2465fbc', // Индивидуальный ключ API
	lang: 'ru_RU', // Используемый язык
	coordorder: 'latlong', // Порядок задания географических координат
	debug: false, // Режим отладки
	version: '2.1', // Версия Я.Карт
};

createApp(App).use(store).use(router).use(createHead()).use(YmapPlugin, settings).mount('#app');
