<template>
	<HeaderComponent :isDisable="true" />
	<div class="notFound__wrapper container">
		<div class="notFound__block">
			<h1>Такой страницы не существует</h1>
			<router-link to="/">Вернуться на главную</router-link>
		</div>
		<img src="@/assets/img/404.png" alt="404" />
	</div>
	<!-- <FooterNavigationComponent /> -->
</template>

<script>
import HeaderComponent from '@/components/UI/HeaderComponent.vue';
import FooterNavigationComponent from '@/components/UI/Footer/FooterNavigationComponent.vue';
import { useHead } from '@vueuse/head';

export default {
	components: { HeaderComponent, FooterNavigationComponent },
	name: 'NotFound',
	setup() {
		useHead({
			title:
				'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» | Страница не найдена',
			meta: [
				{
					name: 'description',
					content: '404 - Страница не найдена',
				},
			],
		});
	},
};
</script>
