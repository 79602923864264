<template>
	<nav class="footer-navigation">
		<div class="footer-navigation__wrapper container">
			<div class="footer-navigation__links left-side">
				<div class="footer-navigation__logo">
					<router-link :to="'./'">
						<img class="logo" src="@/assets/img/logo.svg" alt="logo" />
					</router-link>
					<h3>ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</h3>
				</div>
				<div class="footer-navigation__contacts">
					<router-link class="footer-navigation__link" :to="'/filials'">3 филиала в Запорожской области</router-link>
				</div>
				<div class="footer-navigation__contacts">
					<a class="footer-navigation__link tel" href="tel:+7-990-228-53-70">+7-990-234-50-40</a>
				</div>
			</div>

			<!-- <FooterLinksComponent v-for="i in 4" :key="i" :column="i - 1" /> -->
		</div>
	</nav>
	<FooterComponent />
</template>

<script>
import FooterComponent from './FooterComponent.vue';
import FooterLinksComponent from './FooterLinksComponent.vue';

export default {
	name: 'FooterNavigation',
	components: { FooterComponent, FooterLinksComponent },

	data() {
		return {};
	},
};
</script>

<style scoped></style>
