<template>
	<header class="header">
		<img
			id="specialButton"
			src="@/assets/img/vision.png"
			alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
			title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
		/>
		<div class="header-title container">
			<div class="header-title__logo-block">
				<router-link :to="'./'">
					<img class="logo" src="@/assets/img/logo.webp" alt="Logo" />
				</router-link>
			</div>
			<div class="header-title__title-block">
				<img class="logo" src="@/assets/img/logo.svg" alt="Logo" />
				<h1 class="header-title__title-block__subtitle">
					ФЕДЕРАЛЬНАЯ СЛУЖБА ПО НАДЗОРУ В СФЕРЕ ЗАЩИТЫ ПРАВ ПОТРЕБИТЕЛЕЙ И БЛАГОПОЛУЧИЯ ЧЕЛОВЕКА
				</h1>
			</div>
		</div>
		<h2 class="header-title__title-block__title">УПРАВЛЕНИЕ РОСПОТРЕБНАДЗОРА ПО ЗАПОРОЖСКОЙ ОБЛАСТИ</h2>
		<HeaderNavigationComponent />
		<HeaderHomeNavigationComponent v-if="isHomeNavigation" />
	</header>
</template>

<script>
import HeaderHomeNavigationComponent from './HeaderHomeNavigationComponent.vue';
import HeaderNavigationComponent from './HeaderNavigationComponent.vue'
export default {
	components: { HeaderHomeNavigationComponent, HeaderNavigationComponent },
	props: {
		isDisable: Boolean,
		isHomeNavigation: Boolean,
	},
};
</script>

<style lang="scss"></style>
