<template>
	<HeaderComponent :isDisable="true" />
	<main class="documentsList container">
		<h1 class="documentsList__title">
			Перечень документов для проведения санитарно-эпидемиологической экспертизы на медицинскую и фармацевтическую
			деятельность.
		</h1>

		<ol class="documentsList__olList">
			<li>Свидетельство о внесении записи в единый государственный реестр юридических лиц;</li>
			<li>Свидетельство о постановке на учет юридического лица в налоговом органе;</li>
			<li>Свидетельство о государственной регистрации юридического лица;</li>
			<li>Устав;</li>
			<li>
				Схема ответственных за сбор, хранение, удаление отходов. Приказ о назначении ответственного лица за сбор,
				хранение и удаление отходов. Удостоверение (свидетельство) об обучении ответственного лицам правилам сбора,
				хранения и утилизации медицинских отходов. Договор на вывоз медицинских отходов;
			</li>
			<li>Перечень работ и услуг. Объём проводимых услуг;</li>
			<li>Экспликация помещений (техпаспорт БТИ);</li>
			<li>Программа производственного контроля;</li>
			<li>Договор на оказание услуг по стирке белья;</li>
			<li>Договор на обслуживание медицинской техники (приборы, фиксирующие параметры микроклимата)</li>
			<li>
				Договор или государственный контракт на проведение дератизационных, дезинфекционных и дезинсекционных работ;
			</li>
			<li>Договор на утилизацию ТБО;</li>
			<li>Договор на утилизацию ртутьсодержащих отходов;</li>
			<li>
				Протокол измерений шума, измерений уровней электромагнитного, электростатического полей искусственной
				освещенности, коэффициента пульсации, испытаний воздуха рабочих помещений, воды питьевой, дистиллированной воды;
			</li>
			<li>Перечень материально-технического оснащения и средствами измерения на 2024.г;</li>
			<li>
				Журналы: проверок юридического лица, регистрации входных накладных, журнал дефектуры; учета параметров
				температуры в холодильнике; учета параметров температуры и влажности; журнал учета медикаментов; журнал учета
				препаратов с ограниченным сроком годности; журнал поступления расходных дезсредств; журнал учета генеральных и
				текущих уборок;
			</li>
			<li>Договор на осуществление программы производственного контроля;</li>
			<li>Штатное расписание;</li>
			<li>
				Протокол испытаний приточно-вытяжной вентиляции. Паспорта на вентиляционные установки (систему). Акты ревизии;
			</li>
			<li>Протоколы по выполнению программы производственного контроля;</li>
			<li>
				Медицинские книжки. Данные о профилактических прививках (дифтерия, столбняк, брюшной тиф, грипп, гепатит В, корь
				(до 35 лет), краснуха (до 20 лет);
			</li>
			<li>Сертификаты соответствия и инструкции на используемые дезсредства;</li>
		</ol>

		<div class="documentsList__doc-wrapper">
			<div class="documentsList__doc-item">
				<img width="50" src="@/assets/img/rtf.webp" alt="rtf" />
				<div class="documentsList__doc-description">
					<a :href="STATEMENT"> Заявление о выдаче СЭЗ </a>
					<span>64.0 Кб</span>
				</div>
			</div>
		</div>
	</main>
	<!-- <FooterNavigationComponent /> -->
</template>

<script>
import HeaderComponent from '@/components/UI/HeaderComponent.vue';
import FooterNavigationComponent from '@/components/UI/Footer/FooterNavigationComponent.vue';
import STATEMENT from '@/assets/documents/Заявление о выдаче СЭЗ.rtf';

import { useHead } from '@vueuse/head';

export default {
	components: { HeaderComponent, FooterNavigationComponent },

	data() {
		return {
			STATEMENT: STATEMENT,
		};
	},

	setup() {
		useHead({
			title:
				'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» | Перечень документов для проведения санитарно-эпидемиологической экспертизы на медицинскую и фармацевтическую деятельность.',
			meta: [
				{
					name: 'description',
					content:
						'Перечень документов для проведения санитарно-эпидемиологической экспертизы на медицинскую и фармацевтическую деятельность.',
				},
			],
		});
	},
};
</script>

<style lang="scss" scoped></style>
