const FooterLinksStore = {
	state: () => ({
		footerLinks: [
			[
				{
					title: 'Прейскурант на услуги',
					alias: 'pricelist',
					url: '/pricelist',
				},
				{
					title: 'Услуги для населения',
					alias: 'servicesforpublic',
					url: '/servicesforpublic',
				},
				{
					title: 'Услуги для ЮЛ и ИП',
					alias: 'servicesforip',
					url: '/servicesforip',
				},
				{
					title: 'Бактериологическая лаборатория',
					alias: 'bacteriological',
					url: '/bacteriological',
				},
				{
					title: 'Вирусологическая лаборатория',
					alias: 'virological',
					url: '/virological',
				},
				{
					title: 'Паразитологическая лаборатория',
					alias: 'parasitological',
					url: '/parasitological',
				},
				{
					title: 'Химическая лаборатория',
					alias: 'chemical',
					url: '/chemical',
				},
				{
					title: 'Токсикологическая',
					alias: 'toxicological',
					url: '/toxicological',
				},
			],
			[
				{
					title: 'Порядок оформления и выдачи ЛМК',
					alias: 'the-procedure-for-registration-and-issuance-of-LMK',
					url: '/the-procedure-for-registration-and-issuance-of-LMK',
				},
				{
					title: 'Порядок проведения гигиенического обучения и аттестации',
					alias: 'the-procedure-for-carrying-out-hygienic-training-and-certification',
					url: '/the-procedure-for-carrying-out-hygienic-training-and-certification',
				},
				{
					title: 'Порядок проведения санитарно-эпидемиологических экспертиз и прохождения документации',
					alias: 'the-procedure-for-conducting-sanitary-and-epidemiological-examinations-and-passing-documentation',
					url: '/the-procedure-for-conducting-sanitary-and-epidemiological-examinations-and-passing-documentation',
				},
				{
					title: 'Перечень документов, используемых при выполнении Органом инспекции работ по оценке соответствия',
					alias: 'list-of-conformity-assessment-documents',
					url: '/list-of-conformity-assessment-documents',
				},
				{
					title: 'Рассмотрение жалоб и апелляций на решения органа инспекции',
					alias: 'consideration-of-complaints-and-appeals',
					url: '/consideration-of-complaints-and-appeals',
				},
			],
			[
				{
					title: 'Медицинские осмотры работников эпидемиологически значимых профессий',
					alias: 'medical-examinations-of-workers',
					url: '/medical-examinations-of-workers',
				},
				{
					title: 'Информация о консультативном центре',
					alias: 'information-about-the-advisory-center',
					url: '/information-about-the-advisory-center',
				},
				{
					title: 'Горячие линии',
					alias: 'hotlines',
					url: '/hotlines',
				},
				{
					title: 'Инфотека',
					alias: 'infoteka',
					url: '/infoteka',
				},
				{
					title: 'Банк судебных решений',
					alias: '/judgment-bank',
					url: '/judgment-bank',
				},
				{
					title: 'Консультационные пункты в филиалах',
					alias: 'filials',
					url: '/filials',
				},
			],
			[
				{
					title: 'Структура центра',
					alias: 'sructure',
					url: '/sructure',
				},
				{
					title: 'Документы',
					alias: 'documents',
					url: '/documents',
				},
				{
					title: 'Закупки',
					alias: 'procurement',
					url: '/procurement',
				},
				{
					title: 'Противодействие коррупции',
					alias: 'anti-corruption',
					url: '/anti-corruption',
				},
				{
					title: 'Вакансии',
					alias: 'jobs',
					url: '/jobs',
				},
				{
					title: 'Телефонный справочник',
					alias: 'phonebook',
					url: '/phonebook',
				},
				{
					title: 'Обращения граждан',
					alias: 'appeals-of-citizens',
					url: '/appeals-of-citizens',
				},
			],
		],
	}),
	rootGetters: {},
	mutations: {},
	actions: {},
};

export default FooterLinksStore;
