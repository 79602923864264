import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '@/pages/Home.vue';
import DocumentsList from '@/pages/DocumentsList.vue';
import News from '@/pages/News.vue';
import NotFound from '@/pages/NotFound.vue';
import FederalServiceDepartment from '@/pages/FederalServiceDepartment.vue';

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomePage,
	},
	{
		path: '/documents-list',
		name: 'documentsList',
		component: DocumentsList,
	},
	{
		path: '/federal-service-department',
		component: NotFound,
		// redirect: {
		// 	name: 'externalRedirect',
		// 	params: { url: 'https://cgezo.ru/federal-service-department' },
		// },
	},
	{
		path: '/news',
		name: 'News',
		component: News,
	},
	{
		path: '/external-redirect/:url',
		name: 'externalRedirect',
		beforeEnter(to) {
			window.location.href = to.params.url;
		},
	},
	{
		path: '/:CatchAll(.*)',
		name: '404',
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
