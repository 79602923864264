const HeaderNavigationStore = {
	state: () => ({
		filialList: [
			{
				id: 1,
				title: 'Филиал ФБУЗ «ЦГиЭ в ЗО» в городе Мелитополь',
				address: '272309, Запорожская область, г.Мелитополь, ул. Сухова, д. 6',
				image: 'http://via.placeholder.com/350x150',
				coordinates: [46.836061, 35.371046],
				tel: '+7-990-234-50-40',
				email: 'fbuz_85@rospotrebnadzor.ru',
			},
			{
				id: 2,
				title: 'Филиал ФБУЗ «ЦГиЭ в ЗО» в Пологовском , Ореховском, Гуляйпольском, и Токмакском районе',
				address: 'Запорожская область, г.Пологи, ул.И.Чеберка, 88',
				image: 'http://via.placeholder.com/350x150',
				coordinates: [47.255104, 35.709187],
				tel: '+7-990-234-50-40',
				email: 'fbuz_85@rospotrebnadzor.ru',
			},
			{
				id: 3,
				title: 'Филиал ФБУЗ «ЦГиЭ» в Васильевском и Михайловском районах',
				address: 'Запорожская область, г.Энергодар, ул. Советская 33/139',
				image: 'http://via.placeholder.com/350x150',
				coordinates: [],
				tel: '+7-990-234-50-40',
				email: 'fbuz_85@rospotrebnadzor.ru',
			},
			{
				id: 4,
				title: 'Филиал ФБУЗ «ЦГиЭ» в г.Бердянск, Бердянском, Черниговском и Приморском районах',
				address: '271100, Запорожская область, г.Бердянск, ул. Свободы, д. 115',
				image: 'http://via.placeholder.com/350x150',
				coordinates: [46.758643, 36.798345],
				tel: '+7-990-234-50-40',
				email: 'fbuz_85@rospotrebnadzor.ru',
			},
		],
	}),
	rootGetters: {},
	mutations: {},
	actions: {},
};

export default HeaderNavigationStore;
