<template>
	<footer class="footer">
		<div class="footer__wrapper container">
			<h5 class="footer__copy">
				© 2023 Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»
			</h5>
		</div>
	</footer>
</template>

<script>
export default {};
</script>
