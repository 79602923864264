import { v4 as uuidv4 } from 'uuid';

const NewsStore = {
  state: {
    news: [
      {
        id: uuidv4(),
        title: 'Горячая линия  Роспотребнадзора',
        content: `
						<p class="news__text--indent">
						В преддверии новогодних праздников в период с 9 по 20 декабря Управление Роспотребнадзора по Запорожской области проводит "горячую линию" по вопросам качества и безопасности детских товаров, выбору новогодних подарков.
						</p>
						<br />
						<p class="news__text--indent"></p>
						Консультирование граждан проводится в будни с 9:00 до 16:00 час.  по следующему номеру  телефона  <b>+7 990-234-50-40  доб. 111</b>
						</p>
						<br />
				`,
        images: [require('@/assets/img/news/hotline.jpg')],
      },
      {
        id: uuidv4(),
        title: 'В период с 25 ноября по 8 декабря проводится Всероссийский экологический диктант.',
        content: `
						<p class="news__text--indent">
						Экодиктант – это ежегодный проект, направленный на формирование экологической культуры, популяризацию экологических знаний среди различных слоев населения. В тестирование включены вопросы про заповедные территории России, Красную книгу РФ и экотуризм.🌍
						</p>     
						<br />
						<p class="news__text--indent">В 2024 году экологический диктант проводится в 6 раз❗️
						</p>
						<br />
						<p class="news__text--indent">Это мероприятие проходит в онлайн-формате на портале <a href="https://экодиктант.рус/">https://экодиктант.рус/</a>, а также на офлайн-площадках. В нем могут принять участие жители всех субъектов Российской Федерации, а также лица, проживающие за рубежом.
						</p>
						<br />
				`,
        images: [require('@/assets/img/news/diktant.jpg')],
      },
      {
        id: uuidv4(),
        title: 'Как организовать летний отдых ребенка и оздоровить его?',
        content: `
						<p class="news__text--indent">
						Одним из направлений организации детского летнего отдыха, сохранения и укрепления здоровья ребенка является отдых в пришкольных лагерях. Особенностью пришкольного лагеря является то, что дети в лагере находятся не полный день (дневное пребывание) и в послеобеденное время могут вернуться к обычному для себя распорядку дня с сохранением возможности принимать участие в запланированных семейных мероприятиях. В пришкольном лагере ребенок занимается организованным и активным отдыхом под присмотром педагогов и медицинских работников, которые принимают активное участие в деятельности лагеря. Нахождение и общение детей происходит среди своих сверстников, что способствует формированию школьного коллектива, развитию адаптационных механизмов и коммуникативных навыков, а также создает условия для проявления способностей детей в непринужденной обстановке. 
						</p>     
						<br />
						<p class="news__text--indent">Педагоги и медицинские работники, составляя программу летнего отдыха, предусматривают все возможности для раскрытия потенциала ребенка, его способностей, не связанных с обучением, а также в ходе планирования летнего отдыха предусматривается комплекс физически оздоровительных и укрепляющих здоровье упражнений, проводимых по распорядку дня.
						</p>
						<br />
						<p class="news__text--indent">Так, режим дня в лагере имеет оздоровительную направленность. В течение дня дети пребывают на свежем воздухе, выполняют оздоровительные и физические упражнения развивающие в детях спортивные навыки так необходимых подрастающему и развивающемуся организму, воспитывая в ребенке уверенность в своих силах и возможностях. Вовлечение детей в физкультурные мероприятия происходит согласно распределению их на физкультурные группы с учетом состояния здоровья детей.
						</p>
						<br />
						<p class="news__text--indent">В лагере воспитатели оказывают психологическое сопровождение детей, направленное на предотвращение возникновения конфликтных ситуаций, путем развития коммуникативных навыков направленных на улучшение взаимоотношений, взаимоуважения и взаимопонимания.
						</p>
						<br />
						<p class="news__text--indent">Смена в пришкольном лагере может длиться до 21 дня. За указанный период путем тщательной проработки и планирования распорядка дня удается сохранить и укрепить здоровье, провести ряд профилактических мероприятий направленных на развитие ловкости, силы и выносливости ребенка, удовлетворить его интеллектуальные и творческие потребности.
						</p>
						
				`,
      },
      {
        id: uuidv4(),
        title: 'О проведении «горячей линии» по актуальным вопросам защиты прав потребителей в судебном порядке',
        content: `
						<p class="news__text--indent">
						Управление Роспотребнадзора по Запорожской области и Федеральное бюджетного учреждения здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» информирует, что в период с 11 по 22 марта 2024 года проводится «горячая линия» по актуальным вопросам защиты прав потребителей в судебном порядке. 
						</p>     
						<br />
						<p class="news__text--indent">В период проведения специалисты Управления проконсультируют граждан о правах потребителей, расскажут, что делать в случае неурегулирования спорной ситуации между продавцом и покупателем, об изменениях в проведении надзорных мероприятий в сфере защиты прав потребителей, ответят на вопросы, касающиеся реализации закона о защите прав потребителей, порядке и способах судебной защиты прав и законных интересов, помогут в оформлении претензий, исковых заявлений в судебные органы, а также дадут ответы на другие актуальные вопросы. </p>
						<br />
						<p class="news__text--indent">За консультацией можно обратиться по телефону «горячей линии» +7 990-234-50-40, а также на личном приеме специалистов по адресу: Запорожская область, г. Мелитополь, ул. Сухова, д. 6.</p>
						<br />
						<p class="news__text--indent">Часы работы горячей линии: Понедельник - Четверг: 09:00 - 17:00; Пятница: 09:00 - 16:45; Перерыв: 13:00 - 13:45.</p>
						
				`,
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default NewsStore;
