import { createStore } from 'vuex';
import HeaderNavigationStore from './modules/HeaderNavigationStore.js';
import FooterLinksStore from './modules/FooterLinksStore.js';
import FilialsStore from './modules/FilialsStore.js';
import NewsStore from './modules/NewsStore.js';

export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		HeaderNavigationStore,
		FooterLinksStore,
		FilialsStore,
		NewsStore,
	},
});
