<template>
	<nav class="home-navigation">
		<div class="home-navigation__wrapper container">
			<!-- <div class="home-navigation__leftSide">
				<div class="home-navigation__leftSide-linksList home-navigation__leftSide-linksList_secondProportions">
					<div v-for="link in homePageMenu" :key="link.alias">
						<router-link class="home-navigation__leftSide-linksList__main-link" :to="link.url">{{
							link.title
						}}</router-link>
					</div>
				</div>
			</div> -->
			<div class="home-navigation__center">
				<router-link class="home-navigation__center-documentsList" to="/documents-list"
					>Перечень документов для проведения санитарно-эпидемиологической экспертизы на медицинскую и фармацевтическую
					деятельность.</router-link
				>
			</div>
		</div>
	</nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			homePageMenu: (state) => state.HeaderNavigationStore.homePageMenu,
		}),
	},
};
</script>

<style lang="scss" scoped>
.navigation__wrapper {
	justify-content: space-between;
}
</style>
