const HeaderNavigationStore = {
	state: () => ({
		homePageMenu: [
			{
				title: 'ОБ УПРАВЛЕНИИ',
				alias: 'aboutDepartment',
				url: '/federal-service-department',
			},
			{
				title: 'НОВОСТИ',
				alias: 'news',
				url: '/news',
			},
		],
	}),
	rootGetters: {},
	mutations: {},
	actions: {},
};

export default HeaderNavigationStore;
